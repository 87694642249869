import { Component, OnInit } from '@angular/core';
import { Policy } from '../../models/policy.model';
import { CPPolicyService } from 'src/app/corporate-portal/services/cp-policy.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/services/alert.service';
import { CPMemberService } from '../../services/CPMember.service';
import { SelfMember } from '../../models/self-member.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupModelComponent } from 'src/app/shared/components/popup-model/popup-model.component';

@Component({
  selector: 'app-cp-nominee-enroll',
  templateUrl: './cp-nominee-enroll.component.html',
  styleUrls: ['./cp-nominee-enroll.component.scss']
})
export class CpNomineeEnrollComponent implements OnInit {

  currentPolicy: Policy;
  policies = [];
  nomineeDetailForm: FormGroup;
  today: Date = new Date();
  maxDate: Date = new Date();
  minDate: Date = new Date();
  selfMember: SelfMember;
  isNomineeDataSubmitting: boolean = false;
  employeeId: string;
  isHrView: boolean;

  constructor(private policyService: CPPolicyService, private fb: FormBuilder,
              private alertService: AlertService, private cpMemberService: CPMemberService,
              private modalService: NgbModal) {
    this.maxDate.setFullYear(this.today.getFullYear() - 18);
    this.minDate.setFullYear(this.today.getFullYear() - 118);
   }

  ngOnInit(): void {
    this.policyService.currentPolicy.subscribe(async (policy: any) => {
      this.currentPolicy = policy;
    });
    this.policyService.policies.subscribe(policies => {
      this.policies = policies;
    });
    this.cpMemberService.selfMemberDetails.subscribe(member => {
      this.selfMember = member;
      if(this.isHrView){
        this.employeeId = '';
      }
      else{
        this.employeeId = this.selfMember.memberIdEmpId;
      }
    })
    this.cpMemberService.isHrView.subscribe(isHr => {
      if(isHr){
        this.isHrView = true;
        this.employeeId = '';
      }else{
        this.isHrView = false;
        this.employeeId = this.selfMember.memberIdEmpId;
      }
    });
    this.createNomineeDetailForm();
  }

  createNomineeDetailForm(){
    this.nomineeDetailForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['',[Validators.maxLength(50),Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      relationship: ['', Validators.required],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      mobileno: ['', [Validators.required, Validators.pattern('^[6-9][0-9]{9}$')]],
      pan: ['', [Validators.maxLength(10), Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]{1}$')]],
      employeeId: ['', Validators.required],
    });
  }

  onNamePolicyChanged(policy) {
    this.policyService.currentPolicy.next(policy);
  }

  onSubmit(event: Event){
    event.preventDefault();
    this.isNomineeDataSubmitting = true;
    this.nomineeDetailForm.value.employeeId = this.employeeId;
    this.nomineeDetailForm.value.policyNo = this.currentPolicy.policyNumber;
    const data = JSON.stringify(this.nomineeDetailForm.value);

    this.cpMemberService.submitNomineeEnroll(data).then(data => {
      this.isNomineeDataSubmitting = false;
      if(this.isHrView){
        this.nomineeDetailForm.reset();  
      }
      else{
        //We dont want to reset the employeeId in case if the user is not HR
        this.nomineeDetailForm.reset({
          employeeId: this.employeeId
        });
      }
      const modalRef = this.modalService.open(PopupModelComponent, {
        backdrop: 'static',
        keyboard: false,
        size: 'sm',
        centered: true
      })
      modalRef.componentInstance.content = "Details saved successfully"
      modalRef.componentInstance.saveButton = "Close"
      modalRef.componentInstance.close.subscribe((data: boolean) => {
        modalRef.dismiss();
      });
      modalRef.componentInstance.save.subscribe((data: string) => {
        modalRef.close();
      })
    }).catch(err => {
      console.log(err);
      this.isNomineeDataSubmitting = false;
      const modalRef = this.modalService.open(PopupModelComponent, {
        backdrop: 'static',
        keyboard: false,
        size: 'sm',
        centered: true
      })
      modalRef.componentInstance.content = "Unable to submit your response"
      modalRef.componentInstance.saveButton = "Close"
      modalRef.componentInstance.close.subscribe((data: boolean) => {
        modalRef.dismiss();
      });
      modalRef.componentInstance.save.subscribe((data: string) => {
        modalRef.close();
      })
    });
  }

}
