import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup-model',
  templateUrl: './popup-model.component.html',
  styleUrls: ['./popup-model.component.scss'],
})
export class PopupModelComponent implements OnInit {
  @Input() title = `Information`;
  @Input() saveButton = `Save`;
  @Input() isDisableSaveButton = false;
  @Input() showCloseButton = false;
  @Input() content: string = '';
  @Output() close = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<string>();
  @Output() dontSave = new EventEmitter<string>();
  @Input() showLoader = false;
  @Input() extraClass = '';
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  closePopup() {
    this.close.emit(false);
  }

  savePopup() {
    this.save.emit(this.saveButton);
  }

  dontSavePopup(){
    this.save.emit("No");
  }
}
