import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog-popup',
  templateUrl: './confirm-dialog-popup.component.html',
  styleUrls: ['./confirm-dialog-popup.component.scss']
})
export class ConfirmDialogPopupComponent implements OnInit {

  title = "Confirmation";
  saveButtonText = "Yes";
  @Output() action = new EventEmitter<string>();
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  close(event: any){
    this.action.emit(event);
    this.modalService.dismissAll(ConfirmDialogPopupComponent);
  }

}
