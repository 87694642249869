<app-popup-model
  [title]="title"
  (close)="close($event)"
  [saveButton]="saveButtonText"
  (save)="close($event)"
  (dontSave)="close($event)"
  [showCloseButton]="true"
>
<div class="form-card">
    <div>
        ABHA ID & ABHA Address is different. Do you want to update the new ABHA ID & ABHA Address?
    </div>
</div>
</app-popup-model>